.hero {
    position: relative;
    overflow: hidden;
    padding-bottom: fluid-unit(80);

    @include breakpoint('tablet') {
        padding-bottom: fluid-unit(100);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: fluid-unit(40);
        background-color: $blue;
        z-index: -1;

        @include breakpoint('tablet') {
            bottom: fluid-unit(50);
        }

    }

    .container {
        @include breakpoint('desktop') {
            position: relative;
        }
    }

    &__container {
        position: relative;
        z-index: 2;
        padding-bottom: fluid-unit(40);
        background-color: $blue;
        color: $white;
        
        @include breakpoint('tablet') {
            @include grid;
            align-items: center;
            padding-bottom: fluid-unit(40);
            margin-top: fluid-unit(-120);
        }

        @include breakpoint('laptop') {
            padding-bottom: 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: fluid-unit(-120);
            right: 0;
            bottom: fluid-unit(-40);
            left: 50%;
            background-color: $dark-blue;
            z-index: -1;
            width: 50vw;

            @include breakpoint('tablet') {
                left: 100%;
                bottom: fluid-unit(-60);
            }
    
        }   

        a {
            color: $red;
            font-weight: $bold;
            text-decoration: none;
            border-bottom: 1px solid;

            &:hover {
                color: $red-active;
            }

        }

        
    }

    &__logo {
        width: fluid-unit(150);
        margin-top: fluid-unit(20);
        margin-bottom: fluid-unit(-20);
        position: relative;
        z-index: 3;

        @include breakpoint('tablet') {
            margin-bottom: fluid-unit(10);
            width: fluid-unit(200);
        }

        @include breakpoint('laptop') {
            margin-bottom: fluid-unit(-20);
        }
    }

    &__media {
        position: relative;

        @include breakpoint('tablet') {
            @include column(6, 13);
            grid-row: 1;
            display: flex;
            align-items: center;
            margin-right: -8.3333%;

            &:after {
                content: '';
                position: absolute;
                top: -50%;
                right: 0;
                bottom: fluid-unit(-180);
                left: 50%;
                background-color: $dark-blue;
                z-index: -1;
            }

        }

        @include breakpoint('desktop') {
            @include column(6, 13);
        }

    }

    &__image {
        width: 100%;
        display: block;
        margin: 0;
    }

    &__odour-logo {
        position: absolute;
        top: 7.75%;
        right: 9.11111111111111%;
        width: 17.888888888888886%;
    }

    &__content {
        text-align: center;

        @include breakpoint('tablet') {
            @include column(1, 7);
            grid-row: 1;
            text-align: left;
            padding: fluid-unit(160) 0 fluid-unit(40) 0;
        }

        @include breakpoint('desktop') {
            @include column(1, 6);
        }
    }  

    &__text {
        font-size: fluid-unit(18);
    }

    &__title-line {
        display: block;

        &--primary {
            color: $red;
        }

    }

    &__logos {
        
        padding-top: fluid-unit(20);

        @include breakpoint('tablet') {
            width: 80%;
        }

        .logo-grid__image {
            height: fluid-unit(60);
        }

        @include breakpoint('tablet') {
            .logo-grid__item {
                justify-content: flex-start;
            }
        }

    }

    &__trim {
        position: absolute;
        bottom: 0;
        right: 0;
        left: fluid-unit(80);
        z-index: 3;

        @include breakpoint('tablet') {
            @include grid();
            left: 0;
            padding-left: fluid-unit(20);
            padding-right: fluid-unit(20);
            margin-left: auto;
            margin-right: auto;
            width: fluid-unit(1280);
            max-width: 100%;
            padding: 0 fluid-unit(20);
            height: fluid-unit(100);

            .trim {
                width: 100vw;
                position: absolute;
                top: 0;
                left: calc(8.3333% + 20px);
            }

        }

    }

    &__trim-ribbon {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: fluid-unit(40);
        background-color: $white;
        right: 0;
        z-index: 2;
        @include breakpoint('tablet') {
            height: fluid-unit(50);
        }
    }

}