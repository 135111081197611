@import '~normalize.css';

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    &,
    &::before,
    &::after {
        box-sizing: border-box;
    }

    &::placeholder {
        color: $black;
        opacity: 0.5;
    }

    &:focus::placeholder {
        color: transparent !important;
    }

    &::-moz-selection {
        background: lighten($black, 30%);
    }

    &:focus {
        box-shadow: 0 0 2px 1px $blue;
        outline: none;
    }

    &::selection {
        background: $red;
    }
}