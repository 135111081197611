.CybotCookiebotDialogBodyButton,
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
    background-color: $red !important;
    border: none !important;
}

.CybotCookiebotDialogNavItemLink {
    color: $red !important;

    &.CybotCookiebotDialogActive {
        border-bottom-color: $red !important;
    }

}

#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $red !important;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover {
    color: $red !important;
}


#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
    border: none !important;
}

.Cookiebotwidget-consent-icon svg {
    fill: $red !important;
}

#CookiebotWidget .CookiebotWidget-consent-details button {
    color: $red !important;
}