.carousel {
    position: relative;
    padding-bottom: fluid-unit(104);

    &__navigation {
        position: absolute;
        top: 100%;
        right: 0;
        display: flex;
        gap: fluid-unit(12);
        margin-top: fluid-unit(20);
    }

    &__item {
        background-color: $white;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
        padding: fluid-unit(48) fluid-unit(32);
        text-align: center;
        display: flex;
        height: 100%;
        flex-direction: column;
    }

    &__text {
        justify-self: flex-end;
        margin-bottom: 0;
        min-height: fluid-unit(120);

        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            text-align: left;
        }

    }

    &__icon {
        display: block;
        margin: 0 auto fluid-unit(20) auto;
        width: fluid-unit(100);
    }

    &__image {
        display: block;
        margin: 0 auto fluid-unit(20) auto;
        width: fluid-unit(200);
    }

    &__heading {
        color: $red;
        
        &--blue {
            color: $blue;
        }

    }

    &__button {
        background-color: $red;
        width: fluid-unit(64);
        height: fluid-unit(64);
        transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
        cursor: pointer;

        &.swiper-button-disabled {
            opacity: 0.3;
        }

        &:not(.swiper-button-disabled):hover {
            background-color: $red-active;
        }

    }

    &__arrow {
        fill: $white;
    }

    &__title {
        margin: 0 0 fluid-unit(40) 0;
        
        @include breakpoint('tablet') {
            padding-left: calc(16.666% + #{fluid-unit(7)});
        }

    }

    .swiper {
        overflow: visible;

        @include breakpoint('tablet') {
            padding-left: calc(16.666% + #{fluid-unit(7)});
        }

        .wrapper {
            white-space: no-wrap;
        }



        &-slide {
            height: auto;
            opacity: 0.4;
            transition: opacity 0.3s ease-in-out;
            white-space: normal;

            &.swiper-slide-active {
                opacity: 1;
            }

            @include breakpoint('tablet') {
                &.swiper-slide-next {
                    opacity: 1;
                }
            }

        }

    }   

}