.season-switcher {

    &__toggle {
        position: fixed;
        z-index: 12;
        bottom: 20px;
        right: 50%;
        transform: translateX(50%);
        cursor: pointer;

        @include breakpoint('tablet') {
            right: 20px;
            bottom: auto;
            top: 20px;
            transform: translateX(0);
        }

    }

    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 150%;
        height: 100%;
        transform: translateX(-100%);
        background-color: $white;
        transition: transform 2s, width 1.2s, background-color 0s 2s;
        z-index: 11;

    }

}

.toggle {
    display: flex;
    align-items: center;
    gap: fluid-unit(15);
    background-color: $white;
    padding: fluid-unit(10) fluid-unit(20);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
    border-radius: fluid-unit(40);

    &__label {
        font-weight: 600;
        font-size: fluid-unit(14);
    }

    &__switch {
        height: fluid-unit(50);
        width: fluid-unit(100);
        border-radius: fluid-unit(25);
        background-color: rgba($blue, 0.1);
        padding: fluid-unit(5);
        position: relative;
        transition: background-color 1s;
    }

    &__marker {
        width: fluid-unit(40);
        height: fluid-unit(40);
        border-radius: 100%;
        position: absolute;
        top: fluid-unit(5);
        left: fluid-unit(5);
        background-color: $blue;
        transition: left 1s, background-color 1s;
    }


}


body {

    &.is-active {

        .season-switcher {
            

            &::before {
                transform: translateX(100%);
                width: 100%;
                background-color: $all-black;
            }

            .toggle {

                &__marker {
                    left: fluid-unit(55);
                    background-color: $red;
                }

                &__switch {
                    background-color: rgba($red, 0.1);
                }

            }


        }

    }

    &.is-transitioning {

        .season-switcher {
            pointer-events: none;
        }

    }

}