$num-grid-col: 12;
$grid-gutter-width: 40;

@mixin grid() {
    display: grid;
    grid-template-columns: (repeat($num-grid-col, 1fr));
    gap: fluid-unit($grid-gutter-width);
}

@mixin column($start, $end) {
    grid-column: #{$start} / #{$end};
}
