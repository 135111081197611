@mixin small-bullet-list {
    padding: 0;
    margin: 0;
    line-height: 2.2;
    list-style: none;

    > li {
        position: relative;
        padding-left: fluid-unit(30);

        &:before {
            content: '';
            position: absolute;
            left: fluid-unit(15);
            top: fluid-unit(18);
            width: fluid-unit(3);
            height: fluid-unit(3);
            background-color: currentColor;
            border-radius: 100%;
        }

    }
}

@mixin small-bullet-list-winter {
    padding: 0;
    margin: 0;
    line-height: 2.2;
    list-style: none;

    > li {
        position: relative;
        padding-left: fluid-unit(30);

        &:before {
            content: '';
            position: absolute;
            left: fluid-unit(15);
            top: fluid-unit(18);
            width: fluid-unit(5);
            height: fluid-unit(5);
            background-color: currentColor;
            border-radius: 100%;
        }

    }
}

@mixin dash-list {
    padding: 0;
    margin: 0;
    line-height: 2.2;
    list-style: none;

    > li {
        position: relative;
        padding-left: fluid-unit(30);

        &:before {
            content: '';
            position: absolute;
            left: fluid-unit(15);
            top: fluid-unit(12);
            width: fluid-unit(8);
            height: fluid-unit(2);
            background-color: currentColor;
            border-radius: 0;
        }

    }
}

@mixin visually-hidden {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    margin: -1px; padding: 0; border: 0;
}