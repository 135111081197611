.image-lockup { 

    &__trim {
        bottom: fluid-unit(70);
        
        @include breakpoint('phablet') {
            right: fluid-unit(46);
        }

    }

}