.container {
    padding-left: fluid-unit(20);
    padding-right: fluid-unit(20);
    margin-left: auto;
    margin-right: auto;
    width: fluid-unit(1280);
    max-width: 100%;

    &--flush {
        padding-left: 0;
        padding-right: 0;
    }

}