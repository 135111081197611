.overlay-grid {
    @include grid;
    position: fixed;
    top: 0;
    left: 50%;
    width: fluid-unit(1280);
    max-width: 100%;
    transform: translateX(-50%);
    height: 100%;
    pointer-events: none;
    z-index: 100;
    padding: 0 fluid-unit(20);

    > span {
        height: 100%;
        background-color: rgba(green, 0.1);

        &:nth-child(1) { grid-column-start: 1; }
        &:nth-child(2) { grid-column-start: 2; }
        &:nth-child(3) { grid-column-start: 3; }
        &:nth-child(4) { grid-column-start: 4; }
        &:nth-child(5) { grid-column-start: 5; }
        &:nth-child(6) { grid-column-start: 6; }
        &:nth-child(7) { grid-column-start: 7; }
        &:nth-child(8) { grid-column-start: 8; }
        &:nth-child(9) { grid-column-start: 9; }
        &:nth-child(10) { grid-column-start: 10; }
        &:nth-child(11) { grid-column-start: 11; }
        &:nth-child(12) { grid-column-start: 12; }

    }

}