.footer {
    background-color: $all-black;
    color: $white;

    &__logo-item {

        &--primary {
            border-bottom: 1px solid $black;
            
            @include breakpoint('laptop') {
                border-bottom: none;
                border-right: 1px solid $black;
            }

        }

    }

    &__sub {
        border-top: 1px solid $black;
    }

}