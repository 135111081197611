.trim {
    display: block;
    height: fluid-unit(80);
    background-image: url(../images/Trim-Large.svg);
    background-repeat: repeat-x;
    pointer-events: none;
    background-size: auto 100%;

    @include breakpoint('tablet') {
        height: fluid-unit(100);
    }

    &--small {
        background-image: url(../images/Trim-Small.svg);
        height: fluid-unit(40);

        @include breakpoint('tablet') {
            height: fluid-unit(60);
        }

    }

    &--lockup {
        background-image: url(../images/Trim-Lockup.svg);
        height: fluid-unit(50);
    }

    &--duo {
        background-image: url(../images/trim-duo.svg);
        height: fluid-unit(50);
        background-position: right;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    &--dark {
        background-image: url(../images/Trim-Large-dark.svg);
    }

}