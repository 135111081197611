.compare {

    $root: #{&};
    $is-active: #{$root + ".active"};
    $is-demoing: #{$root + ".is-demoing"};

    position: relative;
    z-index: 2;

    &--left {
        padding-right: fluid-unit(40);
    }

    &--right {
        padding-left: fluid-unit(40);
    }

    &__wrap {
        position: relative;
        
        #{$is-active} & {
            cursor: grabbing;
        }

        &--video {
            padding-bottom: 56.25%;
        }

    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__base {
        display: block;
        margin: 0;
        pointer-events: none;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background-size: auto 100%;
        transform: translateZ(0);
        backface-visibility: hidden;

        #{$is-demoing} & {
            transition: width 0.5s cubic-bezier(0.85, 0, 0.15, 1);
        }

    }

    &__handle {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        z-index: 2;

        #{$is-demoing} & {
            transition: left 0.5s cubic-bezier(0.85, 0, 0.15, 1);
        }
    }

    &__icon {
        display: block;
        width: fluid-unit(64);
        height: fluid-unit(64);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba($white, 0.9);
        border-radius: 100%;
        padding: fluid-unit(14);
        z-index: 2;
        cursor: grab;
        fill: $black;

        #{$is-active} & {
            cursor: grabbing;
            fill: $red;
        }

    }

    &__divider {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: fluid-unit(5);
        background-color: $white;
        z-index: 1;
    }

}


.comparison-block {
    overflow: hidden;

    @include breakpoint('tablet') {

        .callout {

            position: relative;
            padding-bottom: fluid-unit(60);
            
            &__title {
                padding-left: calc(8.3333% + #{fluid-unit(10)} );
            }

            &__trim {
                position: absolute;
                bottom: 0;
                width: 100vw;
                height: fluid-unit(60);
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 50vw;
                background-color: $red;
            }


        }

    }

    &--left {

        @include breakpoint('tablet') {

            .compare {
                padding-right: 0;
                margin-right: calc( 16.6666% + #{fluid-unit(20)} );
            }

            .callout {

                margin-left: auto;
                
                &:after {
                    left: 100%;
                }

                &__trim {
                    left: 0;
                }

                
            }

        }

        @include breakpoint('desktop') {

            .compare {
                margin-right: calc( 16.6666% + #{fluid-unit(20)} );
                margin-left: fluid-unit(20);
            }


        }

    }

    &--right {

        @include breakpoint('tablet') {

            .compare {
                margin-left: calc( 16.6666% + #{fluid-unit(20)} );
                padding-left: 0;
            }

            .callout {

                &:after {
                    right: 100%;
                }

                &__trim {
                    right: 0;
                }
            }

        }

        @include breakpoint('desktop') {

            .compare {
                margin-left: calc( 16.6666% + #{fluid-unit(20)} );
                margin-right: fluid-unit(20);
            }


        }

    }

}