.alert {
    overflow: hidden;

    .carousel {
        max-width: 100%;
        padding: fluid-unit(30) 0 fluid-unit(46) 0;

        .swiper {
            padding-left: 0;
            padding-right: fluid-unit(200);
        }

        &__navigation {
            left: 0;
            right: auto;
        }

        &--do-not {
            padding-top: 6rem;
        }

    }

    .container {
        padding: 0 0 fluid-unit(70) 0;
        width: fluid-unit(665);
    }

}