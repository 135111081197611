.footer {
    background-color: $blue;
    color: $white;
    padding: fluid-unit(80) 0 fluid-unit(20) 0;
    position: relative;
    font-size: 0.875rem;

    @include breakpoint('laptop') {
        padding: fluid-unit(80) 0 fluid-unit(40) 0;
    }

    &__logos {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: fluid-unit(40);

        @include breakpoint('laptop') {
            @include column(1, 10);
            margin-bottom: 0;
            flex-wrap: nowrap;
        }

    }

    &__logo-item {
        width: 50%;
        padding: fluid-unit(20);

        @include breakpoint('tablet') {
            width: 25%;
        }

        @include breakpoint('laptop') {
            height: fluid-unit(70);
            width: auto;
            padding: fluid-unit(10);
        }

        img {
            display: block;
            margin: 0 auto;
            height: fluid-unit(58);
            
            @include breakpoint('laptop') {
                max-height: 100%;
            }

        }

        &--primary {
            width: 100%;
            border-bottom: 1px solid $light-blue;
            
            @include breakpoint('mobile') {
                padding-top: 0;
            }

            @include breakpoint('laptop') {
                border-bottom: none;
                border-right: 1px solid $light-blue;
                padding-right: fluid-unit(40);
                margin-right: fluid-unit(20);
                width: auto;
                height: fluid-unit(90);
            }

            img {
                height: fluid-unit(70);
                margin-left: 0;

                @include breakpoint('laptop') {
                    height: auto;
                }

            }
        }
        
    }

    &__link {
        color: $red;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

    }

    &__text {

        p, ul, ol {
            margin: 0 0 1rem 0; 

            &:last-child {
                margin-bottom: 0;
            }

        }

        ul,ol {
            padding: 0 0 0 1rem;

            li + li {
                margin-top: 0.5rem;
            }

        }

    }

    &__sub {
        border-top: 1px solid $light-blue;
        padding: fluid-unit(12) 0;
    }

    &__primary {
        @include breakpoint('laptop') {
            @include grid;
            margin-bottom: fluid-unit(80);
        }
    }

    &__secondary {
        @include breakpoint('tablet') {
            @include grid;
            margin-bottom: fluid-unit(80);
        }

        .footer__text {

            &--1 {
                @include column(2, 6);
            }

            &--2 {
                @include column(7, 12);
            }

        }

    }

    &__sub {
        margin-top: fluid-unit(40);
        display: flex;
        gap: fluid-unit(20);
        justify-content: space-between;

        @include breakpoint('laptop') {
            margin-top: 0;
        }

        p {
            margin: 0;
            font-size: fluid-unit(12);
            text-align: right;
        }

    }

    &__cookies-link {
        color: $white;
        text-decoration: none;
    }


}

.back-to-top {
    display: flex;
    align-items: center;
    font-size: fluid-unit(16);
    gap: fluid-unit(20);
    white-space: nowrap;
    cursor: pointer;
    position: absolute;
    top: fluid-unit(10);
    right: fluid-unit(10);

    @include breakpoint('phablet') {
        top: fluid-unit(105);
        right: fluid-unit(20);
    }

    @include breakpoint('laptop') {
        @include column(10, 13);
        position: relative;
        top: auto;
        right: auto;
        justify-content: flex-end;
    }

    &__icon {
        background-color: $red;
        color: $white;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: $red-active;
        }

        svg {
            fill: currentColor;
            display: block;
            width: fluid-unit(64);
            height: fluid-unit(64);
        }
    }

}