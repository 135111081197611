.alert {
    background-color: $grey;
    padding: fluid-unit(48) fluid-unit(32);

    @include breakpoint('tablet') {
        display: flex;
        align-items: flex-start;
        gap: fluid-unit(20);
    }

    &__icon {
        display: block;
        width: fluid-unit(64);
        margin-bottom: fluid-unit(20);
    }

    &__heading {
        margin-bottom: fluid-unit(20);
    }

    &__link {
        font-weight: bold;
        color: inherit;
    }

    &__text {
        margin: 0;

        &--small {
            font-size: fluid-unit(14);
        }

    }

    &__list {
        @include small-bullet-list;
        margin-bottom: 1rem;

        > li {
            line-height: 1.4;
            margin-left: fluid-unit(-14);
            
            &::before {
                top: fluid-unit(12);
            }

            + li {
                margin-top: fluid-unit(20);
            }

        }
    }

    + .alert {
        margin-top: fluid-unit(48);
    }

    &--shadow {
        box-shadow: 2px 0px 16px 0px rgba(0, 0, 0, 0.06);
    }

}