html,
body {
    font-family: $font-family;
    color: $black;
    font-weight: $regular;
    margin: 0;
    padding: 0;
    position: relative;
    font-size: fluid-unit(16);
    line-height: 1.5;
    overflow-x: hidden;
}

h1,h2,h3,h4,h5 {
    font-weight: $bold;
    margin: 0;
}

h1,
%h1 {
    font-size: fluid-unit(44);
    line-height: 1.0625;

    @include breakpoint('laptop') {
        font-size: fluid-unit(64);
    }

}

h2,
%h2 {
    font-size: fluid-unit(44);
    line-height: 1.09090909091;
}

h3,
%h3 {
    font-size: fluid-unit(28);
    line-height: 1.1875;

    @include breakpoint('laptop') {
        font-size: fluid-unit(32);
    }
}

h4,
%h4 {
    font-size: fluid-unit(24);
    line-height: 1.1666666667;
}

h5,
%h5 {
    font-size: fluid-unit(20);
    line-height: 1.2;
}

img {
    max-width: 100%;
}

.h1 { @extend %h1; }
.h2 { @extend %h2; }
.h3 { @extend %h3; }
.h4 { @extend %h4; }
.h5 { @extend %h5; }