.carousel {

    &__icon {
        height: fluid-unit(100);
        width: auto;
        max-width: 100%;
    }

    &--reduce {
        padding-top: fluid-unit(104);
    }

    &--do-not {
        padding-top: fluid-unit(100);
        padding-bottom: fluid-unit(208);
    }
    
}