.symptoms {
    background-color: $dark-blue;
    color: $white;
    padding-bottom: fluid-unit(60);

    @include breakpoint('tablet') {
        padding-bottom: fluid-unit(120);
    }

    &__trim {
        position: relative;
        height: fluid-unit(80);
        margin-bottom: fluid-unit(30);

        @include breakpoint('tablet') {
            height: fluid-unit(100);
            margin-bottom: fluid-unit(60);
        }

        &::after {
            content: '';
            position: absolute;
            top: -1px;
            left: -50vw;
            width: 150vw;
            height: calc(50% + 1px);
            background-color: $white;
            z-index: 1;
        }

        .trim {
            position: absolute;
            top: 0;
            height: 100%;
            width: 150vw;
            right: calc(8.33333% + #{fluid-unit(3)});
            z-index: 2;
            background-position: top right;
        }
    }

    &__grid {
        padding-top: fluid-unit(30);
        @include breakpoint('tablet') {
            padding-top: fluid-unit(60);
        }

        + .symptoms__grid {
            padding-top: 0;
        }

    }

    &__text {
        font-size: fluid-unit(18);

        ul {
            @include small-bullet-list;
        }

    }

    &__cta {

        margin-top: fluid-unit(40);

        &-title {
            font-size: fluid-unit(28);
            line-height: 1.1875;

            @include breakpoint('tablet') {
                font-size: fluid-unit(31);
            }
        }
        

        span {
            color: $red;
        }
    }

    @include breakpoint('tablet') {

        &__grid {
            @include grid();
            align-items: center;
        }

        &__imagery {
            @include column(2, 7);
            grid-row: 1;

            .image-lockup {
                padding-left: 0;
            }

        }

        &__text {
            @include column(7, 12);
            grid-row: 1;
        }

        &__cta {
            @include column(2, 12);
            grid-row: 2;
        }

    }

    @include breakpoint('laptop') {

        &__cta {
            @include column(3, 11);
        }

    }

    

}