/* Colours */
$red: #F74D0D;
$red-active: #D03A12;
$dark-red: #7D230B;
$blue: #003863;
$light-blue: #809BB1;
$dark-blue: #002542;
$grey: #EBEBEB;
$black: #373A36;
$all-black: #000;
$white: #FFFFFF;