.logo-grid {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: fluid-unit(20);

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}