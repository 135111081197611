.image-lockup {
    
    position: relative;
    padding-bottom: fluid-unit(92);

    @include breakpoint('phablet') {
        padding-left: fluid-unit(46);
        padding-right: fluid-unit(46);
    }

    &__primary {
        display: block;
        width: 100%;
        margin: 0;
    }

    &__secondary {
        position: absolute;
        bottom: 0;
        right: fluid-unit(-20);
        width: 52.156862745098046%;
        z-index: 2;
        min-width: fluid-unit(220);

        @include breakpoint('phablet') {
            right: 0;
        }
    }

    &__trim {
        position: absolute;
        bottom: fluid-unit(67);
        width: 70%;
        right: fluid-unit(46);
        z-index: 1;
    }


}
