.hero {
    background-color: $all-black;

    &:after {
        bottom: auto;
        background: linear-gradient(45deg, rgba(254, 254, 254, 0) 0%, rgba(254, 254, 254, 0) 40%, rgb(254, 254, 254) 95%, rgb(254, 254, 254) 100%);
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 2;
        opacity: 0.3;
    }

    &__container {
        background-color: transparent;

        &:after {
            display: none;
        }

    }

    &__media {
        position: relative;

        @include breakpoint('tablet') {
            @include column(9, 13);
        }

        &:after {
            display: none;
        }

    }

    &__image {
        padding: fluid-unit(30);

        @include breakpoint('tablet') {
            padding: 0 fluid-unit(50) 0 0;
        }
    }

    &__content {
        text-align: center;

        @include breakpoint('tablet') {
            @include column(1, 9);
            text-align: left;
        }

    }  

    &__logos {
        
        .logo-grid__image {
            height: fluid-unit(60);

            &--large {
                height: fluid-unit(140);
            }

        }

    }

}