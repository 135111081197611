.symptoms {
    background-color: $red;
    color: $all-black;

    &__grid {

        + .symptoms__grid {

            padding-top: fluid-unit(30);
            @include breakpoint('tablet') {
                padding-top: fluid-unit(60);
            }

        }

    }

    &__text {
        font-size: fluid-unit(20);

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            line-height: fluid-unit(30);

            > li {
                padding-left: 0;

                &:before {
                    display: none;
                }

            }

        }

        li + li {
            margin-top: fluid-unit(15);
        }

    }

    &__dropcap {
        color: $all-black;
        font-weight: 800;
    }

    &__cta {
        color: $all-black;
        text-align: center;

        span {
            color: $white;
        }

    }

    @include breakpoint('laptop') {

        &__cta {
            @include column(2, 12);
        }

    }

}