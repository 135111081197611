.callout {
    background-color: $red;
    color: $white;
    margin-top: fluid-unit(-40);

    @include breakpoint('tablet') {
        margin-top: fluid-unit(-120);
    }
    
    &__title {
        @extend %h1;
        padding: fluid-unit(80) fluid-unit(20) fluid-unit(64) fluid-unit(20);

        @include breakpoint('tablet') {
            padding: fluid-unit(184) fluid-unit(190) fluid-unit(64) fluid-unit(190);
        }

    }

    &__primary,
    &__secondary {
        display: block;
    }

    &__secondary {
        color: $dark-red;
    }

    &__trim {
        background-color: $white;
        position: relative;
        z-index: 2;
    }

}

.callout-wrapper {

    @include breakpoint('tablet') {
        padding: 0 fluid-unit(20);

        .callout {
            width: calc(91.6666666667% - 3px);
        }

    }

}