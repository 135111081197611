.text-section {
    padding: fluid-unit(64) 0;
    font-size: fluid-unit(18);

    @include breakpoint('tablet') {
        padding: fluid-unit(80) 0;
    }

    &--more-places {
        padding-top: fluid-unit(36);

        @include breakpoint('tablet') {
            padding-top: fluid-unit(70);
            padding-bottom: fluid-unit(100);
        }

        .text-section {
            &__heading {
                margin-top: fluid-unit(48);
            }
        }

    }

    &--outdoors,
    &--holiday {
        padding-bottom: fluid-unit(48);

        @include breakpoint('tablet') {
            padding-top: fluid-unit(100);
            padding-bottom: fluid-unit(80);
        }

    }

    &--lpg {
        padding-top: fluid-unit(12);
        padding-bottom: fluid-unit(120);
    }

    &--alarm {
        padding-top: fluid-unit(44);

        @include breakpoint('tablet') {
            padding-top: fluid-unit(12);
        }

    }

    &--info {
        padding-top: 0;
        @include breakpoint('tablet') {
            padding-bottom: fluid-unit(120);
        }
    }

    &--cookies {

        a {
            color: $red;
            font-weight: bold;
        }

        .text-section {
            &__title {
                margin-top: fluid-unit(40);
                margin-bottom: fluid-unit(40);
            }
        }

    }

    &--include {
        padding-top: 0;
    }

    &__container {
        @include breakpoint('tablet') {
            @include grid;
        }
    }

    &__content {
        @include breakpoint('tablet') {
            @include column(3, 11);
        }

        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    .text-section {

        &__heading {
            margin-bottom: fluid-unit(20);
        }
    
        &__sub {
            font-size: fluid-unit(20);
            margin-bottom: fluid-unit(20);
        }

    }
    
    p {
        margin: 0 0 1rem 0;
    }

    &__small {
        font-size: fluid-unit(14);
    }

    &__list {    
        @include small-bullet-list;
        margin-bottom: fluid-unit(16);

        > li {
            line-height: 1.4;
            
            &::before {
                top: fluid-unit(12);
            }

            + li {
                margin-top: fluid-unit(20);
            }

        }

    }
    

}