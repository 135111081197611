.text-section {

    &--more-places {
 
        @include breakpoint('tablet') {
            padding-top: fluid-unit(70);
            padding-bottom: fluid-unit(70);
        }

    }

    &--maintenance {
        padding-top: 0;
    }

    .text-section {
    
        &__sub {
            margin-top: fluid-unit(20);
        }

    }

    ul {    
        @include small-bullet-list-winter;
        margin-bottom: 1rem;

        > li {
            line-height: 1.4;
            
            &::before {
                top: fluid-unit(12);
            }

            + li {
                margin-top: fluid-unit(20);
            }

        }

        ul {
            @include dash-list;
            margin-top: 1rem;
        }

    }

}