.column-copy {
    display: flex;
    flex-direction: column;

    @include breakpoint('tablet') {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: fluid-unit(15);
    }

}