.logo-grid {

    @include breakpoint('tablet') {
        grid-template-columns: repeat(3, 1fr);
    }

    &__item {

        &--large {
            grid-column: 1 / span 2;

            @include breakpoint('tablet') {
                grid-column: 3;
                grid-row: 1 / span 2;
            }

        }

    }

    &__image {
        &--large {
            display: block;
            margin: 0 auto;
            height: fluid-unit(140);
        }
    }

}